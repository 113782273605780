import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

type Props = {
  image: string
  title: string
}

const Book = ({title, image}: Props) =>
  <Card sx={{ height: 280 }}>
    <CardMedia
      component="img"
      height="280"
      image={image}
    />
    {/* <CardContent>
      <Typography gutterBottom variant="h5" component="div">
        {title}
      </Typography>
    </CardContent> */}
  </Card>

export default Book