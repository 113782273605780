import React, { useState } from 'react'
import './App.css'
import { TextField, Autocomplete } from '@mui/material'
import { CirclePicker } from 'react-color'
import Book from './components/Book'
import FlatList from 'flatlist-react'
import books from './data/books.json'
import Fuse from 'fuse.js'

const options: any = {}
books.forEach(book => book.tags.forEach(tag => options[tag] = true))
const tags = Object.keys(options).sort()


function rgb2lab(rgb: number[]){
  let r = rgb[0] / 255, g = rgb[1] / 255, b = rgb[2] / 255, x, y, z;
  r = (r > 0.04045) ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
  g = (g > 0.04045) ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
  b = (b > 0.04045) ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;
  x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
  y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.00000;
  z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;
  x = (x > 0.008856) ? Math.pow(x, 1/3) : (7.787 * x) + 16/116;
  y = (y > 0.008856) ? Math.pow(y, 1/3) : (7.787 * y) + 16/116;
  z = (z > 0.008856) ? Math.pow(z, 1/3) : (7.787 * z) + 16/116;
  return [(116 * y) - 16, 500 * (x - y), 200 * (y - z)]
}

function deltaE(labA: number[], labB: number[]) {
  let deltaL = labA[0] - labB[0];
  let deltaA = labA[1] - labB[1];
  let deltaB = labA[2] - labB[2];
  let c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2]);
  let c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2]);
  let deltaC = c1 - c2;
  let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
  deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
  let sc = 1.0 + 0.045 * c1;
  let sh = 1.0 + 0.015 * c1;
  let deltaLKlsl = deltaL / (1.0);
  let deltaCkcsc = deltaC / (sc);
  let deltaHkhsh = deltaH / (sh);
  let i = deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh;
  return i < 0 ? 0 : Math.sqrt(i);
}

const fuse = new Fuse(books, {
  keys: ['title', 'subTitle', 'contributor'],
  findAllMatches: true,
  threshold: 0.45,
  // distance: 100,
})

function App() {
  const [filter, setFilter] = useState<string[]>([])
      , [search, setSearch] = useState<string>('')
      , [color, setColor] = useState<any>('')
  console.log(color)
  let bookSelection = search.length ? fuse.search(search).map(result => result.item) : books
  bookSelection = filter.length ? bookSelection.filter(book => filter.every(f => book.tags.includes(f))) : bookSelection

  if (color) {
    const lab = rgb2lab([color.r, color.g, color.b])
    bookSelection = bookSelection.filter(book => !book.colors.every(color => {
      if (deltaE(lab, color) > 14.1)
        return true
      // @ts-ignore
      book.delta = color[4]
      return false
    }))
    // @ts-ignore
    .sort((a, b) => b.delta - a.delta)
  }

  return (
    <div className="App">
      <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', padding: 20}}>
        <h3>PÜSTET - gibt Pustet ¨</h3>
        <TextField variant="standard" label="Suche nach Titel/Autor..." style={{width: '100%', marginBottom: 20}} value={search} onChange={(e) => setSearch(e.currentTarget.value)}/>

        <strong style={{marginBottom: 20}}>Enthaltene Farben</strong>

        <CirclePicker color={color} onChange={({rgb}) => {
          if (rgb && rgb.r === color.r && rgb.g === color.g && rgb.b === color.b)
            setColor('')
          else
            setColor(rgb)
        }}/>

        <div style={{height: 30}}/>

        <Autocomplete
          multiple
          size="small"
          fullWidth
          style={{height: 80}}
          options={tags}
          onChange={(e, value) => setFilter(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Suche nach Cover-Inhalt..."
              multiline
            />
          )}
        />
      </div>
      <div style={{flex: 6, overflow: 'auto'}}>
        <FlatList
          displayGrid
          list={bookSelection}
          renderOnScroll
          renderItem={(book: any) => <Book image={`/covers/${book.id}.jpg`} title="test" />}
          renderWhenEmpty={() => <div>Keine passenden Bücher gefunden.</div>}
        />
      </div>
    </div>
  );
}

export default App;
